export enum SupportedLocales {
  en = "en-US",
  ru = "ru-RU",
  ua = "uk-UA",
  fr = "fr-FR",
  es = "es-ES",
  ja = "ja-JP",
  de = "de-DE",
  raw = "raw",
  pt = "pt-PT",
  tr = "tr-TR",
  ar = "ar-AE",
  cs = "cs-CZ",
}

export const SupportedLocaleList = [
  SupportedLocales.en,
  SupportedLocales.de,
  SupportedLocales.es,
  SupportedLocales.fr,
  SupportedLocales.ja,
  SupportedLocales.ru,
  SupportedLocales.ua,
  SupportedLocales.raw,
  SupportedLocales.pt,
  SupportedLocales.tr,
  SupportedLocales.ar,
  SupportedLocales.cs,
];

export const DEFAULT_LOCALE = SupportedLocales.en;
