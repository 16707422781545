import { Injectable, NgZone } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Division } from "../models/division.model";
import {Entity} from "../models/entity.model";
import {Ring} from "../models/rings.model";

@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private toaster: ToastrService
  ) {}

  public getUserEvents(): Observable<any> {
    return this.http.get(`${environment.APIURL}api/events/my`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getEventRoles(eventId: number): Observable<any> {
    return this.http.get(`${environment.APIURL}api/events/${eventId}/roles`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getEventConfig(eventId: number): Observable<any> {
    return this.http.get(`${environment.APIURL}api/events/${eventId}/config/options`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getEventStaffMap(eventId: number): Observable<any> {
    return this.http.get(`${environment.APIURL}api/events/${eventId}/staff/list`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public updateEventConfigItemPublicDraws(eventId: number, enable: boolean): Observable<any> {
    let enabled = enable ? "enabled" : "disabled";
    return this.http.put(`${environment.APIURL}api/events/${eventId}/config/public-draws/${enabled}`, '').pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public updateEventConfigItemPublicTimeTable(eventId: number, enable: boolean): Observable<any> {
    let enabled = enable ? "enabled" : "disabled";
    return this.http.put(`${environment.APIURL}api/events/${eventId}/config/public-timetable/${enabled}`, '').pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public callMedic(eventId: number, data: any): Observable<any> {
    return this.http.put(`${environment.APIURL}api/events/${eventId}/notification/call-medic`, data).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getEventsAssignedToJudgeUser(): Observable<any> {
    return this.http.get(`${environment.APIURL}api/events/judge`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getEventJudgeRings(eventId: number): Observable<any> {
    return this.http.get(`${environment.APIURL}api/events/${eventId}/judge/rings`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getEventRings(eventId: number): Observable<Ring[]> {
    return this.http.get(`${environment.APIURL}api/events/${eventId}/rings`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getDivisions(eventId: number): Observable<Division[]> {
    return this.http.get<Division[]>(`${environment.APIURL}api/event/divisions/${eventId}/allowed`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getDivisionsWithCompetitors(eventId: number, competitors: any[]): Observable<Division[]> {
    const params = {
      competitors,
    };

    return this.http.post<Division[]>(`${environment.APIURL}api/event/divisions/${eventId}/allowed`, params).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getDivisionAllowedPersons(divisionId: number): Observable<any> {
    return this.http.get<Division[]>(`${environment.APIURL}api/event/divisions/${divisionId}/self/allowed/persons`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getDivisionCompetitors(divisionId: number, competitors: any[]): Observable<any> {
    const params = {
      competitors,
    };

    return this.http.post<Division[]>(`${environment.APIURL}api/event/divisions/${divisionId}/self/allowed/persons`, params).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  public getUserLeagues(): Observable<any> {
    return this.http.get(`${environment.APIURL}api/leagues/my`).pipe(
        catchError(this.handleError),
        map((res) => res)
    );
  }

  private errorHandler(response: any) {
    this.zone.run(() => {
        this.toaster.error(response.message);
    });
  }

  private handleHttpError = (error: HttpErrorResponse): void => {
   return this.errorHandler(error);
  };

  private handleError = (
    error: HttpErrorResponse,
  ): Observable<any> => {
    const errorMessage = this.handleHttpError(error);
  
    return of({Errors: [errorMessage]});
  };
}