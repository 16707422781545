<div class="header">
  <h2 mat-dialog-title class="title2">{{ labelMap.SERVICE_FEE_ADJUSTMENT }}</h2>
  <div class="end">
    <svg
      class="close"
      (click)="closeDialog()"
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <line
        y1="-1"
        x2="12"
        y2="-1"
        transform="matrix(-0.707107 -0.707106 0.707108 -0.707106 10.4844 8.48535)"
        stroke="#525252"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="12"
        y2="-1"
        transform="matrix(0.707107 -0.707106 0.707107 0.707106 2 9.89941)"
        stroke="#525252"
        stroke-width="2"
      />
    </svg>
  </div>
</div>

<div class="dialog-container">
  <mat-dialog-content>
    <span class="description">{{
      labelMap.SERVICE_FEE_ADJUSTMENT_DESCRIPTION
    }}</span>

    <div class="form-container">
      <div class="form-group">
        <label for="adjustment">{{
          labelMap.SERVICE_FEE_ADJUSTMENT_ADJUSTMENT
        }}</label>
        <div class="adjustment-group">
          <input
            matInput
            type="number"
            id="adjustment"
            placeholder="0.00"
            [(ngModel)]="adjustment"
          />
          <span class="unit">{{ event.currencyCode }}</span>
        </div>
      </div>
      <span class="error" *ngIf="error">{{ error }}</span>
      <div class="form-group">
        <label for="reason">{{ labelMap.SERVICE_FEE_ADJUSTMENT_REASON }}</label>
        <textarea
          matInput
          id="reason"
          placeholder="{{ labelMap.SERVICE_FEE_ADJUSTMENT_REASON_PLACEHOLDER }}"
          rows="3"
          [(ngModel)]="reason"
        ></textarea>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-button class="cancel-button" (click)="closeDialog()">
      {{ labelMap.CANCEL }}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="apply-button"
      (click)="applyFilters()"
    >
      {{ labelMap.APPLY }}
    </button>
  </mat-dialog-actions>
</div>
