import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgFor, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import { EventsSelectorService } from "src/app/shared/services/events-selector.service";

@Component({
  selector: "app-searchable-custom-select",
  templateUrl: "./searchable-custom-select.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgFor,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    LocalizationModule,
  ],
  styleUrls: ["./searchable-custom-select.component.scss"],
})
export class SearchableCustomSelectComponent implements OnInit {
  @ViewChild("searchInput") searchInput!: ElementRef<HTMLInputElement>;

  dropdownOpen = false;
  searchTerm = "";
  selectedItem: any | null = null;

  events: any[] = [];

  filteredEvents = [...this.events];

  @Output() filterChanged = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter<any>();
  @Input() searchable: boolean = true;

  role: string = "";

  @ViewChild("searchDopDownList", { static: false }) list!: ElementRef;

  constructor(private eventsSelectorService: EventsSelectorService) {}

  ngOnInit(): void {
    this.eventsSelectorService.role$.subscribe((role) => {
      this.role = role;
    });
    this.eventsSelectorService.selectedEvent$.subscribe((ev) => {
      this.selectedItem = { ...ev };
      if (ev) {
        this.selectedItem.date = this.formatEventDateFromStrings(
          this.selectedItem.eventStartDate ?? this.selectedItem.eventEndDate,
          this.selectedItem.eventEndDate ?? this.selectedItem.eventStartDate
        );
      }
    });

    this.eventsSelectorService.events$.subscribe((events) => {
      const copiedEvents = [];
      if (events) {
        for (let ev of events) {
          if (ev) {
            const date = this.formatEventDateFromStrings(
              ev.eventStartDate ?? ev.eventEndDate,
              ev.eventEndDate ?? ev.eventStartDate
            );

            const copy = { ...ev };
            copy.date = date;
            copiedEvents.push(copy);
          }
        }
      }

      this.events = copiedEvents;

      this.filteredEvents = copiedEvents;
    });
  }

  @HostListener("document:click", ["$event"])
  onClickOutside(event: MouseEvent) {
    const clickedInside = this.list?.nativeElement.contains(event.target);
    if (!clickedInside && this.dropdownOpen) {
      this.toggleDropdown();
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    if (this.dropdownOpen) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 50);
    }
  }

  clearSearch(): void {
    this.toggleDropdown();
    this.searchTerm = "";
    this.filterEvents();
  }

  filterEvents() {
    const searchTermLower = this.searchTerm.toLowerCase().trim();
    if (this.searchable) {
      this.filterChanged.emit(searchTermLower);
    } else {
      if (searchTermLower.length === 0) {
        this.filteredEvents = this.events;
      } else {
        this.filteredEvents = this.events.filter((ev) =>
          ev.name.toLowerCase().includes(searchTermLower)
        );
      }
    }
  }

  selectItem(item: any) {
    this.selectedItem = item;
    this.dropdownOpen = false;
    this.searchTerm = ""; // Clear search after selection
    this.filteredEvents = [...this.events]; // Reset filtered events

    this.itemSelected.emit(item); // Emit selected item
  }

  formatEventDateFromStrings(
    eventStartDateStr: string,
    eventEndDateStr: string
  ): string {
    try {
      // Parse the date strings into Date objects
      if (!eventStartDateStr || !eventEndDateStr) {
        return "";
      }

      const eventStartDate = new Date(eventStartDateStr);
      const eventEndDate = new Date(eventEndDateStr);

      if (isNaN(eventStartDate.getTime()) || isNaN(eventEndDate.getTime())) {
        return "";
      }

      const optionsMonthDay = { month: "long", day: "numeric" } as const;
      const optionsFullDate = {
        month: "long",
        day: "numeric",
        year: "numeric",
      } as const;

      // Check if start and end are the same day
      if (
        eventStartDate.getFullYear() === eventEndDate.getFullYear() &&
        eventStartDate.getMonth() === eventEndDate.getMonth() &&
        eventStartDate.getDate() === eventEndDate.getDate()
      ) {
        // Same day
        return eventStartDate.toLocaleDateString(undefined, optionsFullDate);
      }

      // Check if start and end are in the same year
      if (eventStartDate.getFullYear() === eventEndDate.getFullYear()) {
        if (eventStartDate.getMonth() === eventEndDate.getMonth()) {
          // Same month and year
          return `${eventStartDate.toLocaleDateString(undefined, {
            month: "long",
          })} ${eventStartDate.getDate()}-${eventEndDate.getDate()}, ${eventStartDate.getFullYear()}`;
        } else {
          // Same year, different months
          return `${eventStartDate.toLocaleDateString(
            undefined,
            optionsMonthDay
          )} - ${eventEndDate.toLocaleDateString(
            undefined,
            optionsMonthDay
          )}, ${eventStartDate.getFullYear()}`;
        }
      } else {
        // Different years
        return `${eventStartDate.toLocaleDateString(
          undefined,
          optionsFullDate
        )} - ${eventEndDate.toLocaleDateString(undefined, optionsFullDate)}`;
      }
    } catch (e) {
      console.error(e);
      return "";
    }
  }
}
