<div class="uv-line-1"></div>
<div class="wrap-footer">
  <div class="footer">
    <div>
      <div
        [innerHTML]="IconUvFooter | safe : SafeTypes.html"
        class="logo"
      ></div>
      <div class="uv-label-9">Uventex Labs Corporation</div>
      <br />
      <div class="uv-label-9 click" *ngFor="let p of policies">
        <a href="{{ p.external }}">{{ p.label | transloco }}</a>
      </div>
      <div class="uv-label-9 click">
        <a href="#" class="termly-display-preferences">{{
          "POLICY_CONSENT_PREFERENCES" | transloco
        }}</a>
      </div>
    </div>

    <div class="section-company">
      <div class="uv-label-7">
        {{ "FOOTER_COMPANY_AND_PRODUCTS" | transloco }}
      </div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com" target="_blank">{{
          "FOOTER_UVENTEX_SPORTS_HUB" | transloco
        }}</a>
      </div>
      <div class="uv-label-8">
        <a
          href="https://www.uventexlabs.com/studio-management"
          target="_blank"
          >{{ "FOOTER_UVENTEX_STUDIOS" | transloco }}</a
        >
      </div>
      <div class="uv-label-8">
        <a
          href="https://www.uventexlabs.com/event-management"
          target="_blank"
          >{{ "FOOTER_UVENTEX_EVENTS" | transloco }}</a
        >
      </div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com/videos" target="_blank">{{
          "FOOTER_UVENTEX_TV" | transloco
        }}</a>
      </div>
    </div>

    <div class="section-company">
      <div class="uv-label-7">{{ "FOOTER_COMMUNITY" | transloco }}</div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com/blog" target="_blank"
          >{{ "FOOTER_UVENTEX_SPORTS_HUB_BLOG" | transloco }}
        </a>
      </div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com/brand" target="_blank">{{
          "FOOTER_DESIGN_AND_BRANDING" | transloco
        }}</a>
      </div>
    </div>

    <div class="section-company">
      <div class="uv-label-7">{{ "SUPPORT" | transloco }}</div>
      <a class="uv-label-8" href="mailto:support@uventex.com">
        support&#64;uventex.com
      </a>

      <div class="social-media">
        <a href="https://www.facebook.com/MYuventex" target="_blank">
          <div [innerHTML]="IconFacebook | safe : SafeTypes.html"></div>
        </a>
        <a
          href="https://www.youtube.com/channel/UC5pnyfSfNG6altM86pxtB3w"
          target="_blank"
        >
          <div [innerHTML]="IconYoutube | safe : SafeTypes.html"></div>
        </a>
        <a href="https://www.instagram.com/uventexlabs/" target="_blank">
          <div [innerHTML]="IconInstagram | safe : SafeTypes.html"></div>
        </a>
        <a href="https://twitter.com/uventexlabs" target="_blank">
          <div [innerHTML]="IconTwitter | safe : SafeTypes.html"></div>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="wrap-company">
  <div class="company">
    <div>{{ "FOOTER_DESIGNED_AND_CREATED" | transloco }}</div>
    <div>{{ "FOOTER_SINCE_2015" | transloco }}</div>
  </div>
</div>
