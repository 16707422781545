<ul class="top-menu" *ngIf="!isDiffUrl">
  <ng-container *ngFor="let m of menu">
    <li *ngIf="m.show">
      <a *ngIf="!m.outside && !m.self" [routerLink]="m.link">
        <div class="name">{{ m.name | transloco }}</div>
      </a>

      <a *ngIf="m.outside && !m.self" [href]="m.link" target="_blank">
        <div class="name">{{ m.name | transloco }}</div>
      </a>

      <a *ngIf="m.outside && m.self" [href]="m.link" target="_self">
        <div class="name">{{ m.name | transloco }}</div>
      </a>
    </li>
  </ng-container>
</ul>
