import {
  CommonModule,
  Location,
  NgClass,
  NgTemplateOutlet,
} from "@angular/common";
import {
  Component,
  ElementRef,
  NgModule,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { LocalizationModule } from "src/app/components/localization";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { NgSelectModule } from "@ng-select/ng-select";
import { LeftPanelComponent } from "src/app/components/left-panel/left-panel.component";
import { DashboardComponent } from "../dashboard/dashboard.component";

@Component({
  selector: "app-dashboard-cont",
  templateUrl: "./dashboard-cont.component.html",
  styleUrls: ["./dashboard-cont.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    LocalizationModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgClass,
    NgSelectModule,
    DashboardComponent,
    LeftPanelComponent,
  ],
})
export class DashboardContComponent implements OnInit, OnDestroy {
  public isSidePanelVisible = false;
  public openLeftPanel$ = new BehaviorSubject<boolean>(false);
  public mobileMenuButtom = new BehaviorSubject<any>(null);
  isCollapsed: boolean = false;

  @ViewChild("menuBtn", { static: false })
  private set menuButton(menuButton: ElementRef<HTMLElement>) {
    if (menuButton) {
      this.mobileMenuButtom.next(menuButton.nativeElement);
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.isSidePanelVisible = true;
  }

  public ngOnDestroy() {}

  openSidePanel() {
    this.isSidePanelVisible = true;
    //this.store.dispatch(new ToggleSidePanel(true));
  }

  closeSidePanel() {
    this.isSidePanelVisible = false;
    //this.store.dispatch(new ToggleSidePanel(false));
  }

  collapsePanel(collapsed: boolean) {
    this.isCollapsed = collapsed;
  }

  public closeLeftPanel(): void {
    this.openLeftPanel$.next(false);
  }

  public toggleLeftPanel(): void {
    const next = !this.openLeftPanel$.value;
    this.openLeftPanel$.next(next);
  }

  public leftPanelClickOutside(isOwn: any) {
    if (!isOwn) {
      this.closeLeftPanel();
    }
  }
}
