import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {
  Athlete,
  AthleteParams,
} from "src/app/pages/athletes/athletes.component";
import { FullAthlete } from "src/app/pages/athlete/athlete.component";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AthletesService {
  public athlete: FullAthlete | undefined;

  public selectedCountry = "";
  public selectedAge = "";
  public selectedLeague = "";
  public searchString: string | undefined;

  public pageSize = 24;
  public pagination = {
    page: 1,
    size: this.pageSize,
    total: 0,
  };
  public params: AthleteParams = {
    offset: 0,
    page: 0,
    size: this.pageSize,
    filter: {},
    // sort: {
    //   orders: [{
    //     direction: "DESC",
    //     property: "firstName"
    //   }]
    // }
  };

  baseUrl: string = environment.APIURL;

  constructor(private http: HttpClient) {}

  getAthletesList(params?: AthleteParams) {
    const path = "api/public/athletes/filter";
    return this.http.post(`${this.baseUrl}${path}`, params);
  }

  getFavoritesAthletesList(params?: AthleteParams) {
    const path = "api/athletes/favorites/filter";
    return this.http.post(`${this.baseUrl}${path}`, params);
  }

  markAthleteAsFavorite(athleteId: number) {
    const path = `api/athletes/favorites/${athleteId}`;
    return this.http.post(`${this.baseUrl}${path}`, {
      isFavorite: true,
    });
  }

  unmarkAthleteAsFavorite(athleteId: number) {
    const path = `api/athletes/favorites/${athleteId}`;
    return this.http.delete(`${this.baseUrl}${path}`);
  }

  getAthlete(id: number) {
    const path = `api/public/athletes/profile/${id}`;
    return this.http.get(`${this.baseUrl}${path}`);
  }

  getCountries() {
    const params = {
      page: 0,
      size: 500,
      filter: {
        excludeIds: [0],
        name: "",
      },
      offset: 0,
    };
    const path = "api/public/dictionary/event/countries/filter";
    return this.http.post(`${this.baseUrl}${path}`, params);
  }

  getLeagues(): Observable<unknown> {
    const params = {
      page: 0,
      size: 1000,
      filter: {
        excludeIds: [0],
        name: "",
      },
      offset: 0,
    };
    const path = "api/public/events/filter/rating-systems";
    return this.http.post(`${this.baseUrl}${path}`, params);
  }

  calculateAge(birthDateString: string): number {
    if (!birthDateString) {
      return 0;
    }
    const birthDate = new Date(birthDateString);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Adjust age if the current date is before the birth date in the current year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  }
}
