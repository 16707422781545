import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { NgxPaginationModule } from "ngx-pagination";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  standalone: true,
  imports: [NgxPaginationModule, MatPaginatorModule],
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Output() pageChangeEvent = new EventEmitter<number>(); // Emit a tab index

  constructor() {}

  pageChanged(event: number) {
    this.pageChangeEvent.emit(event);
  }

  async ngOnInit(): Promise<void> {}
}
