import { Component, OnInit } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CommonModule, DatePipe, NgFor, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { environment } from "../../../environments/environment";
import * as moment from "moment";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import { PaginationComponent } from "../pagination/pagination.component";
import { NgxPaginationModule } from "ngx-pagination";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FinancialPerformanceService } from "src/app/shared/constants/financial-performance.service";
import { firstValueFrom } from "rxjs";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { SafePipe } from "src/app/shared/pipes/safe.pipe";
import { TruncatePipe } from "src/app/shared/pipes/truncate.pipe";
import { RouterModule } from "@angular/router";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { ClickStopPropagation } from "src/app/shared/directives/click-stop-propagation.directive";
import { OverlayModule } from "@angular/cdk/overlay";
import { TranslocoService } from "@ngneat/transloco";
import { MatDialog } from "@angular/material/dialog";
import { FilterFinancialDialogComponent } from "../filter-financial-dialog/filter-financial-dialog.component";
import { AthletesService } from "src/app/shared/constants/athletes.service";
import { Store } from "@ngxs/store";
import { UserState } from "src/app/store/user/user.store";
import { AdjustmentFinanceDialogComponent } from "../adjustment-finance-dialog/adjustment-finance-dialog.component";
import { MatMenuModule } from "@angular/material/menu";

@Component({
  selector: "app-financial-performance",
  templateUrl: "./financial-performance.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgFor,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatCardModule,
    MatSelectModule,
    LocalizationModule,
    PaginationComponent,
    NgxPaginationModule,
    MatPaginatorModule,
    MatDatepickerModule,
    CommonModule,
    RouterModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    OverlayModule,
  ],
  styleUrls: ["./financial-performance.component.scss"],
  providers: [DatePipe],
})
export class FinancialPerformanceComponent implements OnInit {
  events: any = [];
  size: any = 12;
  currentPage: any = 1;
  total: any;
  totalFee = 0;
  totalCountries = 0;
  totalCompetitors = 0;
  residualIncome = 0;

  dateFormats: any = {
    "en-US": "MM/dd/yy",
    "ar-AE": "dd/MM/yy",
    "cs-CZ": "dd.MM.yy",
    "de-DE": "dd.MM.yy",
    "es-ES": "dd/MM/yy",
    "fr-FR": "dd/MM/yy",
    "ja-JP": "yy/MM/dd",
    "pt-PT": "dd/MM/yy",
    "ru-RU": "dd.MM.yy",
    "tr-TR": "dd.MM.yy",
    "uk-UA": "dd.MM.yy",
  };

  dateRange: FormGroup;

  startDate: Date;
  endDate: Date;
  countries: any;
  leagues: any;
  extraFilter: {
    countryIds: any;
    leagueIds: any;
    name: string;
    currency: string;
  };

  public moment = moment;
  currentLang: string = "en";
  sort: null | {
    orders: [
      {
        direction: "ASC" | "DESC";
        property:
          | "name"
          | "eventStartDate"
          | "countryName"
          | "currencyCode"
          | "totalRevenue"
          | "convenienceSum"
          | "residualIncome"
          | "competitorsCount";
      }
    ];
  };

  constructor(
    public financialPerformanceService: FinancialPerformanceService,
    private fb: FormBuilder,
    private store: Store,
    private translocoService: TranslocoService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private athleteService: AthletesService,
    private transloco: TranslocoService
  ) {
    this.transloco.langChanges$.subscribe((lang) => {
      const currentLang = lang.split("-")[0];
      this.currentLang = currentLang;
    });
    this.dateRange = this.fb.group({
      start: [null],
      end: [null],
    });
    this.startDate = new Date(
      this.financialPerformanceService.params.filter.dateFrom
    );
    this.endDate = new Date(
      this.financialPerformanceService.params.filter.dateTo
    );

    this.extraFilter = {
      countryIds: this.financialPerformanceService.params.filter.countryIds,
      leagueIds: this.financialPerformanceService.params.filter.leagueIds,
      name: this.financialPerformanceService.params.filter.name,
      currency: this.financialPerformanceService.params.filter.currency,
    };

    this.sort = this.financialPerformanceService.params.sort;
  }

  sorting(
    property:
      | "name"
      | "eventStartDate"
      | "countryName"
      | "currencyCode"
      | "totalRevenue"
      | "convenienceSum"
      | "competitorsCount"
      | "residualIncome"
  ) {
    if (
      this.sort &&
      this.sort.orders &&
      this.sort.orders.length > 0 &&
      this.sort.orders[0].property === property
    ) {
      if (this.sort.orders[0].direction === "DESC") {
        this.sort.orders[0].direction = "ASC";
      } else {
        this.sort = null;
      }
    } else {
      this.sort = {
        orders: [
          {
            direction: "DESC",
            property: property,
          },
        ],
      };
    }

    this.financialPerformanceService.params.sort = this.sort;

    this.filterEvents();
  }

  isFiltered() {
    return (
      this.extraFilter &&
      ((this.extraFilter.countryIds &&
        this.extraFilter.countryIds.length > 0) ||
        (this.extraFilter.name && this.extraFilter.name.length > 0) ||
        (this.extraFilter.leagueIds && this.extraFilter.leagueIds.length > 0) ||
        (this.extraFilter.currency && this.extraFilter.currency.length > 0))
    );
  }

  async openFilter() {
    if (!this.countries || !this.countries.length) {
      this.countries = await firstValueFrom(
        this.financialPerformanceService.getFilterCountries()
      );
    }
    if (!this.leagues || !this.leagues.length) {
      this.leagues = await firstValueFrom(
        this.financialPerformanceService.getFilterLeagues()
      );
    }

    const labels = [
      "FILTER",
      "RESET_FILTER",
      "COUNTRY",
      "LEAGUE",
      "CURRENCY",
      "SEARCH_BY_NAME",
      "ENTER_NAME",
      "CANCEL",
      "APPLY",
    ];

    let labelMap: any = {};
    for (const label of labels) {
      const trans = this.transloco.translate(label);
      labelMap[label] = trans;
    }

    const data = {
      countries: this.countries,
      leagues: this.leagues,
      filters: this.extraFilter,
      labelMap: labelMap,
    };
    const dialogRef = this.dialog.open(FilterFinancialDialogComponent, {
      width: "450px",
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const filters = result;

        if (filters.countries && filters.countries.length > 0) {
          this.financialPerformanceService.params.filter.countryIds =
            filters.countries;
          this.extraFilter.countryIds = filters.countries;
        } else {
          this.extraFilter.countryIds = [];
          this.financialPerformanceService.params.filter.countryIds = [];
        }

        if (filters.leagues && filters.leagues.length > 0) {
          this.financialPerformanceService.params.filter.leagueIds =
            filters.leagues;
          this.extraFilter.leagueIds = filters.leagues;
        } else {
          this.extraFilter.leagueIds = [];
          this.financialPerformanceService.params.filter.leagueIds = [];
        }

        if (filters.currency && filters.currency.length > 0) {
          this.financialPerformanceService.params.filter.currencyCode =
            filters.currency;
          this.extraFilter.currency = filters.currency;
        } else {
          this.extraFilter.currency = "";
          this.financialPerformanceService.params.filter.currencyCode = "";
        }

        if (filters.name && filters.name.length > 0) {
          this.financialPerformanceService.params.filter.name = filters.name;
          this.extraFilter.name = filters.name;
        } else {
          this.extraFilter.name = "";
          this.financialPerformanceService.params.filter.name = "";
        }

        this.filterEvents();
      }
    });
  }

  pageChanged(event: any) {
    this.financialPerformanceService.params.page = event > 0 ? event - 1 : 0;
    this.filterEvents();
  }

  async filterEvents() {
    const data: any = await firstValueFrom(
      this.financialPerformanceService.getInfo()
    );

    this.size = data.size;
    this.total = data.total;
    this.currentPage = data.page + 1;

    this.processDates(data.items);

    this.events = data.items;

    const totalData: any = await firstValueFrom(
      this.financialPerformanceService.getTotalInfo()
    );

    this.totalFee = totalData.convenienceFees;
    this.totalCountries = totalData.countCountries;
    this.totalCompetitors = totalData.totalCompetitors;
    this.residualIncome = totalData.residualIncome;
  }

  async ngOnInit(): Promise<void> {
    this.dateRange.get("end")?.valueChanges.subscribe((endDate) => {
      const startDate = this.dateRange.get("start")?.value;
      if (!endDate) {
        return;
      }
      this.startDate = startDate;
      this.endDate = endDate;
      this.countries = [];
      this.leagues = [];

      this.financialPerformanceService.params.filter.dateFrom = moment(
        this.startDate
      ).format("yyyy-MM-DD");
      this.financialPerformanceService.params.filter.dateTo = moment(
        this.endDate
      ).format("yyyy-MM-DD");
      this.financialPerformanceService.params.page = 0;

      this.filterEvents();
    });

    this.financialPerformanceService.params.filter.dateFrom = moment(
      this.startDate
    ).format("yyyy-MM-DD");
    this.financialPerformanceService.params.filter.dateTo = moment(
      this.endDate
    ).format("yyyy-MM-DD");
    this.financialPerformanceService.params.page = 0;

    this.filterEvents();
  }

  getReturnUrl(eventId: any) {
    let returnUrl = window.location.href;
    returnUrl = encodeURIComponent(returnUrl).replaceAll("%2F", "___");
    return returnUrl;
  }

  menuContext: any = null;

  setMenuContext(item: any) {
    // Store the clicked item's context
    this.menuContext = { item };
  }

  openAdjustmentDialog(item: any) {
    const labels = [
      "APPLY",
      "CANCEL",
      "ENTER_A_VALID_NUMBER",
      "SERVICE_FEE_ADJUSTMENT",
      "SERVICE_FEE_ADJUSTMENT_ADJUSTMENT",
      "SERVICE_FEE_ADJUSTMENT_DESCRIPTION",
      "SERVICE_FEE_ADJUSTMENT_REASON",
      "SERVICE_FEE_ADJUSTMENT_REASON_PLACEHOLDER",
    ];

    let labelMap: any = {};
    for (const label of labels) {
      const trans = this.transloco.translate(label);
      labelMap[label] = trans;
    }

    const data = {
      event: item.item,
      labelMap: labelMap,
    };
    const dialogRef = this.dialog.open(AdjustmentFinanceDialogComponent, {
      width: "550px",
      data: data,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        // if (result.adjustment) {
        const data = {
          eventId: item.item.eventId,
          convenienceAdjustment: result.adjustment,
          convenienceAdjustmentNote: result.reason || "",
        };
        await firstValueFrom(
          this.financialPerformanceService.putAdjustment(data)
        );
        this.filterEvents();
      }
    });
  }

  openReport(event: any) {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const token = authToken && authToken.token ? authToken.token : null;
    let id = "id";

    const page = "financialReportSuperEvent";
    const eventId = event.superEventId;
    const params = "register=0;isNew=true;";
    let returnUrl: string = this.getReturnUrl(event.eventId);
    const url = `${environment.EVENT_ADMIN_URL}GWTClient.html?&locale=${this.currentLang}#${page};${params}${id}=${eventId};applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl};token=${token}`;
    window.open(url, "_blank");
  }

  processDates(
    items: {
      date?: string;
      eventId: number;
      superEventId: number;
      eventName: string;
      promoterName: string;
      currencyCode: string;
      cityName: string;
      countryName: string;
      eventStartDate: string;
      eventEndDate: string;
      competitors: number;
      totalIncome: number;
      convenienceFees: number;
      creditCardsFees: number;
    }[]
  ) {
    for (const i of items) {
      // if (i.eventStartDate === i.eventEndDate) {
      i.date = i.eventStartDate;
      // } else {
      //   i.date = i.eventStartDate + " - " + i.eventEndDate;
      // }
    }
  }

  downloadReport() {
    this.financialPerformanceService
      .downloadFinancialReportCSV()
      .subscribe((response) => {
        if (response) {
          const pdfBlob = response;
          const downloadUrl = URL.createObjectURL(pdfBlob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download =
            `Financial performance.${this.financialPerformanceService.params.filter.dateFrom}.${this.financialPerformanceService.params.filter.dateTo}.csv`
              .split(" ")
              .join("_");
          link.click();
          URL.revokeObjectURL(downloadUrl);
          link.remove();
        }
      });
  }
}
