import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeBannerService {
  showJoinBanner$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor() {}
}
