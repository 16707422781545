import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MenuServices {
  private openMenu = false;

  get isOpenMenu(): boolean {
    return this.openMenu;
  }

  public switchMenu() {
    this.openMenu = !this.openMenu;
  }

  public offMenu() {
    this.openMenu = false;
  }
}
