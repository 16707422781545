import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import * as moment from "moment";

const FINANCIAL_PERFORMANCE_STORAGE_KEY = "FINANCIAL_PERFORMANCE_STORAGE_KEY";

@Injectable({
  providedIn: "root",
})
export class FinancialPerformanceService {
  public params: any = {
    page: 0,
    size: 12,
    filter: {
      excludeIds: [0],
      // dateFrom: "2025-01-02T17:54:31.012Z",
      // dateTo: "2025-02-02T17:54:31.012Z",
      name: "",
    },
    offset: 0,
  };

  baseUrl: string = environment.APIURL;

  constructor(private http: HttpClient) {
    const params = localStorage.getItem(FINANCIAL_PERFORMANCE_STORAGE_KEY);
    if (params) {
      this.params = JSON.parse(params);
    } else {
      const startDate = new Date();
      const endDate = new Date();
      endDate.setMonth(new Date().getMonth() + 1);

      this.params.filter.dateFrom = moment(startDate).format("yyyy-MM-DD");
      this.params.filter.dateTo = moment(endDate).format("yyyy-MM-DD");
    }
  }

  getInfo(): Observable<unknown> {
    const path = "api/reports/events/financial";
    localStorage.setItem(
      FINANCIAL_PERFORMANCE_STORAGE_KEY,
      JSON.stringify(this.params)
    );
    return this.http.post(`${this.baseUrl}${path}`, this.params);
  }

  getTotalInfo(): Observable<unknown> {
    const path = "api/reports/financial";

    const params = {
      excludeIds: [0],
      dateFrom: this.params.filter.dateFrom,
      dateTo: this.params.filter.dateTo,
      countryIds: this.params.filter.countryIds,
      leagueIds: this.params.filter.leagueIds,
      currencyCode: this.params.filter.currencyCode,
      name: this.params.filter.name,
    };

    return this.http.post(`${this.baseUrl}${path}`, params);
  }

  putAdjustment(data: {
    eventId: any;
    convenienceAdjustment: any;
    convenienceAdjustmentNote: any;
  }) {
    const path = "api/reports/financial/event/adjust";
    return this.http.put(`${this.baseUrl}${path}`, data);
  }

  getFilterCountries() {
    const params: any = {
      dateFrom: this.params.filter.dateFrom,
      dateTo: this.params.filter.dateTo,
    };
    return this.http.post(
      `${this.baseUrl}api/reports/events/countries`,
      params
    );
  }

  getFilterLeagues() {
    const params: any = {
      dateFrom: this.params.filter.dateFrom,
      dateTo: this.params.filter.dateTo,
    };
    return this.http.post(`${this.baseUrl}api/reports/events/leagues`, params);
  }

  downloadFinancialReportCSV(): Observable<any> {
    // http://localhost:28188/api/reports/financial/download/csv?dateFrom=2024-01-01&dateTo=2025-01-01&currencyCode=USD
    let path = "api/reports/financial/download/csv";

    const params: any = {
      dateFrom: this.params.filter.dateFrom,
      dateTo: this.params.filter.dateTo,
    };

    if (this.params.filter.currencyCode)
      params["currencyCode"] = this.params.filter.currencyCode;
    if (this.params.filter.name) params["name"] = this.params.filter.name;
    if (this.params.filter.countryIds)
      params["countryIds"] = this.params.filter.countryIds;
    if (this.params.filter.leagueIds)
      params["leagueIds"] = this.params.filter.leagueIds;

    return this.http.get(`${this.baseUrl}${path}`, {
      responseType: "blob",
      params: params,
    });
  }
}
