import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventsSelectorService {
  events$: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedEvent$: BehaviorSubject<any> = new BehaviorSubject(null);
  role$: BehaviorSubject<string> = new BehaviorSubject("");
  constructor() {}
}
