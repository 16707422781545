<div class="main-container">
  <div class="header-desktop-block">
    <div class="desk-title">
      {{
        (activeOption$ === SignInSignUpOptions.signin
          ? "SIGN_IN"
          : activeOption$ === SignInSignUpOptions.signup
          ? "SIGN_UP"
          : "FORGOT_PASSWORD"
        ) | transloco
      }}
    </div>
    <div class="close-modal-button" autofocus>
      <i (click)="closeClick()" class="fas fa-times close-button"></i>
    </div>
  </div>

  <div class="header-mobile-block">
    <div class="header-mobile">
      <div class="arrow-button" (click)="closeClick()">
        <div class="arrow-icon"></div>
      </div>
      <div class="title-mobile" autofocus>
        {{
          (activeOption$ === SignInSignUpOptions.signin
            ? "SIGN_IN"
            : activeOption$ === SignInSignUpOptions.signup
            ? "SIGN_UP"
            : "FORGOT_PASSWORD"
          ) | transloco
        }}
      </div>
      <div class="empty-block"></div>
    </div>
  </div>

  <div class="form-container">
    <div class="form-section">
      <ng-template [ngIf]="activeOption$ === SignInSignUpOptions.signin">
        <events-signin
          (signInSuccessed)="signInSuccessed()"
          (displaySignUp)="displaySignUp()"
          (displayForgotPassword)="displayForgotPassword()"
        ></events-signin>
      </ng-template>

      <ng-template [ngIf]="activeOption$ === SignInSignUpOptions.signup">
        <events-signup
          [signInAndClose]="signInAndClose"
          (signUpSuccessed)="signUpSuccessed()"
          (displaySignIn)="displaySignIn()"
        ></events-signup>
      </ng-template>

      <ng-template
        [ngIf]="activeOption$ === SignInSignUpOptions.forgotPassword"
      >
        <events-forgot-password
          (displaySignIn)="displaySignIn()"
        ></events-forgot-password>
      </ng-template>
    </div>
  </div>
</div>
