import {Injectable, NgZone} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  openSignInDialog$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private toaster: ToastrService
  ) {}
}
