<div class="cont mat-dialog-container">
  <div class="header">
    <h2 mat-dialog-title class="title">{{ "LOGIN_AS" | transloco }}</h2>
    <button
      class="close-button"
      mat-icon-button
      (click)="closeDialog()"
      aria-label="Close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="form" (ngSubmit)="login()">
    <div mat-dialog-content class="c-body dialog-content">
      <label class="input-label">{{ "USER_NAME_OR_EMAIL" | transloco }}</label>

      <div class="dropdown-container w-100">
      <mat-form-field>
                <input
                        matInput
                        #userSearch
                        formControlName="user"
                        [matAutocomplete]="auto"
                        (input)="getUsersList($event)"
                        (change)="setUser($event)"
                        placeholder="{{'START_TYPING...' | transloco}}"
                >
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUserName">
                    <mat-option *ngFor="let option of userOptions" [value]="option">
                        <div class="venue-title">
                            <span>{{option.email}} </span>
                            <span>{{option.fullName}}</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
      </div>

    </div>
    <div mat-dialog-actions>
      <div class="bottom">
        <button class="btn-5 padd">
          {{ "START_USER_SESSION" | transloco }}
        </button>
      </div>
    </div>
  </form>

</div>
