import { Component } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgFor, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import { MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";

@Component({
  selector: "app-medical-dashboard",
  templateUrl: "./medical-dashboard.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgFor,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    NgIf,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    LocalizationModule,
    MatTableModule,
  ],
  styleUrls: ["./medical-dashboard.component.scss"],
})
export class MedicalDashboardComponent {
  displayedColumns: string[] = [
    "competitor",
    "division",
    "reason",
    "notes",
    "datetime",
    "actions",
  ];
  dataSource = [
    {
      competitor: "John Smith",
      division: "Division name",
      reason: "Concussion",
      notes: "No comment",
      datetime: "Nov 15, 2024 05:25 PM",
    },
    {
      competitor: "John Smith",
      division: "Division name",
      reason: "Concussion",
      notes: "No comment",
      datetime: "Nov 15, 2024 05:25 PM",
    },
    {
      competitor: "John Smith",
      division: "Division name",
      reason: "Concussion",
      notes: "No comment",
      datetime: "Nov 15, 2024 05:25 PM",
    },
    // Add more rows as needed
  ];
}
