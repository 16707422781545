import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  baseUrl: string = environment.APIURL;

  constructor(private http: HttpClient) {}

  getWaiverAndInfo(eventId: number): Observable<unknown> {
    const path = `api/events/${eventId}/editor/waiver-and-info`;
    return this.http.get(`${this.baseUrl}${path}`);
  }

  putWaiverAndInfo(
    eventId: number,
    name: string,
    info: string,
    waiver: string
  ): Observable<unknown> {
    const path = `api/events/${eventId}/editor/waiver-and-info`;
    const data = {
      id: eventId,
      name: name,
      info: info,
      waiver: waiver,
    };
    return this.http.put(`${this.baseUrl}${path}`, data);
  }
}
