<div class="cont">
  <div class="header">
    <h2 mat-dialog-title class="title">{{ labelMap.MEDICAL_NOTE }}</h2>
    <button
      class="close-button"
      mat-icon-button
      (click)="closeDialog()"
      aria-label="Close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="c-body">
    <span class="c-label">{{ labelMap.MEDICAL_NOTE_COMPETITOR }}</span>
    <mat-form-field appearance="fill" class="full-width">
      <input
        class="custom-placeholder-color"
        matInput
        placeholder="{{ labelMap.MEDICAL_NOTE_TYPE_COMPETITOR }}"
      />
    </mat-form-field>

    <span class="c-label">{{ labelMap.MEDICAL_NOTE_DIVISION }}</span>
    <mat-form-field appearance="fill" class="full-width">
      <input
        class="custom-placeholder-color"
        matInput
        placeholder="{{ labelMap.MEDICAL_NOTE_TYPE_DIVISION }}"
      />
    </mat-form-field>

    <span class="c-label">{{ labelMap.MEDICAL_NOTE_DIVISION }}</span>
    <mat-radio-group class="full-width c-radio">
      <mat-radio-button class="no-pad" value="concussion"
        >{{ labelMap.MEDICAL_NOTE_CONCUSSION }}
      </mat-radio-button>
      <mat-radio-button class="no-pad" value="other">{{
        labelMap.MEDICAL_NOTE_OTHER
      }}</mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="fill" class="full-width">
      <input
        class="custom-placeholder-color"
        matInput
        placeholder="{{ labelMap['START_TYPING...'] }}"
      />
    </mat-form-field>

    <span class="c-label">{{ labelMap.MEDICAL_NOTE_ADDITIONAL_NOTES }} </span>
    <textarea
      matInput
      placeholder="{{ labelMap['START_TYPING...'] }}"
      class="t-area"
    ></textarea>
  </div>
  <div mat-dialog-actions>
    <div class="bottom">
      <button class="action-b">{{ labelMap.MEDICAL_NOTE_ADD_NOTE }}</button>
    </div>
  </div>
</div>
