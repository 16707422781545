<div class="component">
  <div class="header">
    <div class="header-title">{{ "WAIVER" | transloco }}</div>
  </div>

  <div class="card-text-area">
    <div class="title">
      {{ "WAIVER_INFO_TITLE" | transloco }}
    </div>
    <div class="subtitle">
      {{ "WAIVER_INFO_SUBTITLE" | transloco }}
    </div>

    <div class="text-area">
      <ckeditor
        class="wh100"
        [editor]="editor2"
        [(ngModel)]="info"
        (ngModelChange)="onEditorInfoChange()"
        (ready)="onReadyEditor($event)"
        [config]="ckeditorConfig2"
      ></ckeditor>
    </div>
    <div class="status-label" [ngClass]="statusClassInfo">
      <div>{{ statusMessageInfo1 | transloco }}</div>
      <div class="label-error">{{ statusMessageInfo2 | transloco }}</div>
      <svg *ngIf="statusClassInfo === 'saved'" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM3.645 7.145L1.85 5.35C1.655 5.155 1.655 4.84 1.85 4.645C2.045 4.45 2.36 4.45 2.555 4.645L4 6.085L7.44 2.645C7.635 2.45 7.95 2.45 8.145 2.645C8.34 2.84 8.34 3.155 8.145 3.35L4.35 7.145C4.16 7.34 3.84 7.34 3.645 7.145Z" fill="#CDD620"/>
      </svg>
      <svg *ngIf="statusClassInfo === 'saving'" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <g clip-path="url(#clip0_9252_87661)">
          <path d="M5 0C6.32608 0 7.59785 0.526784 8.53553 1.46447C9.47322 2.40215 10 3.67392 10 5C10 6.32608 9.47322 7.59785 8.53553 8.53553C7.59785 9.47322 6.32608 10 5 10C3.67392 10 2.40215 9.47322 1.46447 8.53553C0.526784 7.59785 0 6.32608 0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0ZM4.53125 2.34375V5C4.53125 5.15625 4.60938 5.30273 4.74023 5.39062L6.61523 6.64062C6.83008 6.78516 7.12109 6.72656 7.26562 6.50977C7.41016 6.29297 7.35156 6.00391 7.13477 5.85938L5.46875 4.75V2.34375C5.46875 2.08398 5.25977 1.875 5 1.875C4.74023 1.875 4.53125 2.08398 4.53125 2.34375Z" fill="#FFC123"/>
        </g>
        <defs>
          <clipPath id="clip0_9252_87661">
            <rect width="10" height="10" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <svg *ngIf="statusClassInfo === 'error'" xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
        <path d="M5.99914 0C6.33195 0 6.63898 0.167411 6.80773 0.441964L11.8702 8.65625C12.0413 8.93304 12.0413 9.27455 11.8749 9.55134C11.7085 9.82812 11.3968 10 11.0616 10H0.936635C0.601479 10 0.28976 9.82812 0.123354 9.55134C-0.0430526 9.27455 -0.0407088 8.9308 0.128041 8.65625L5.19054 0.441964C5.35929 0.167411 5.66632 0 5.99914 0ZM5.99914 2.85714C5.68742 2.85714 5.43664 3.09598 5.43664 3.39286V5.89286C5.43664 6.18973 5.68742 6.42857 5.99914 6.42857C6.31085 6.42857 6.56164 6.18973 6.56164 5.89286V3.39286C6.56164 3.09598 6.31085 2.85714 5.99914 2.85714ZM6.74914 7.85714C6.74914 7.6677 6.67012 7.48602 6.52947 7.35207C6.38881 7.21811 6.19805 7.14286 5.99914 7.14286C5.80022 7.14286 5.60946 7.21811 5.4688 7.35207C5.32815 7.48602 5.24914 7.6677 5.24914 7.85714C5.24914 8.04658 5.32815 8.22826 5.4688 8.36222C5.60946 8.49617 5.80022 8.57143 5.99914 8.57143C6.19805 8.57143 6.38881 8.49617 6.52947 8.36222C6.67012 8.22826 6.74914 8.04658 6.74914 7.85714Z" fill="#E15F5F"/>
      </svg>
    </div>
  </div>

  <div class="card-text-area">
    <div class="title">
      {{ "WAIVER_TITLE" | transloco }}
    </div>
    <div class="subtitle">
      {{ "WAIVER_SUBTITLE" | transloco }}
    </div>
    <div class="text-area">
      <ckeditor
        class="wh100"
        [editor]="editor1"
        [(ngModel)]="waiver"
        (ngModelChange)="onEditorWaiverChange()"
        (ready)="onReadyEditor($event)"
        [config]="ckeditorConfig1"
      ></ckeditor>
    </div>
    <div class="status-label" [ngClass]="statusClassWaiver">
      <div>{{ statusMessageWaiver1 | transloco }}</div>
      <div class="label-error">{{ statusMessageWaiver2 | transloco }}</div>
      <svg *ngIf="statusClassWaiver === 'saved'" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM3.645 7.145L1.85 5.35C1.655 5.155 1.655 4.84 1.85 4.645C2.045 4.45 2.36 4.45 2.555 4.645L4 6.085L7.44 2.645C7.635 2.45 7.95 2.45 8.145 2.645C8.34 2.84 8.34 3.155 8.145 3.35L4.35 7.145C4.16 7.34 3.84 7.34 3.645 7.145Z" fill="#CDD620"/>
      </svg>
      <svg *ngIf="statusClassWaiver === 'saving'" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <g clip-path="url(#clip0_9252_87661)">
          <path d="M5 0C6.32608 0 7.59785 0.526784 8.53553 1.46447C9.47322 2.40215 10 3.67392 10 5C10 6.32608 9.47322 7.59785 8.53553 8.53553C7.59785 9.47322 6.32608 10 5 10C3.67392 10 2.40215 9.47322 1.46447 8.53553C0.526784 7.59785 0 6.32608 0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0ZM4.53125 2.34375V5C4.53125 5.15625 4.60938 5.30273 4.74023 5.39062L6.61523 6.64062C6.83008 6.78516 7.12109 6.72656 7.26562 6.50977C7.41016 6.29297 7.35156 6.00391 7.13477 5.85938L5.46875 4.75V2.34375C5.46875 2.08398 5.25977 1.875 5 1.875C4.74023 1.875 4.53125 2.08398 4.53125 2.34375Z" fill="#FFC123"/>
        </g>
        <defs>
          <clipPath id="clip0_9252_87661">
            <rect width="10" height="10" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <svg *ngIf="statusClassWaiver === 'error'" xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
        <path d="M5.99914 0C6.33195 0 6.63898 0.167411 6.80773 0.441964L11.8702 8.65625C12.0413 8.93304 12.0413 9.27455 11.8749 9.55134C11.7085 9.82812 11.3968 10 11.0616 10H0.936635C0.601479 10 0.28976 9.82812 0.123354 9.55134C-0.0430526 9.27455 -0.0407088 8.9308 0.128041 8.65625L5.19054 0.441964C5.35929 0.167411 5.66632 0 5.99914 0ZM5.99914 2.85714C5.68742 2.85714 5.43664 3.09598 5.43664 3.39286V5.89286C5.43664 6.18973 5.68742 6.42857 5.99914 6.42857C6.31085 6.42857 6.56164 6.18973 6.56164 5.89286V3.39286C6.56164 3.09598 6.31085 2.85714 5.99914 2.85714ZM6.74914 7.85714C6.74914 7.6677 6.67012 7.48602 6.52947 7.35207C6.38881 7.21811 6.19805 7.14286 5.99914 7.14286C5.80022 7.14286 5.60946 7.21811 5.4688 7.35207C5.32815 7.48602 5.24914 7.6677 5.24914 7.85714C5.24914 8.04658 5.32815 8.22826 5.4688 8.36222C5.60946 8.49617 5.80022 8.57143 5.99914 8.57143C6.19805 8.57143 6.38881 8.49617 6.52947 8.36222C6.67012 8.22826 6.74914 8.04658 6.74914 7.85714Z" fill="#E15F5F"/>
      </svg>
    </div>
  </div>
</div>
