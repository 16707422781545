<div class="container">
  <div class="header">
    <span>{{ "NAME" | transloco }}</span>
    <span>{{ "DIVISION" | transloco }}</span>
    <span>{{ "REASON" | transloco }}</span>
    <span>{{ "NOTES" | transloco }}</span>
    <span>{{ "DATE/TIME" | transloco }}</span>
    <span> {{ " " }} </span>
  </div>

  <div class="m-header">
    <span>{{ "NAME" | transloco }}</span>
    <span>{{ "DIVISION" | transloco }}</span>
    <span>{{ "REASON" | transloco }}</span>
    <span> {{ " " }} </span>
  </div>

  <div *ngFor="let note of dataSource" class="c-row">
    <span class="span1">{{ note.competitor }}</span>
    <span class="span2">{{ note.division }}</span>
    <span class="span2">{{ note.reason }}</span>
    <span class="span2">{{ note.notes }}</span>
    <span class="span2">{{ note.datetime }}</span>
    <img class="icon" src="/assets/icons/elipsis-menu.svg" />
  </div>

  <div *ngFor="let note of dataSource" class="m-row">
    <div class="first-line">
      <span class="span1">{{ note.competitor }}</span>
      <span class="span2">{{ note.division }}</span>
      <span class="span2">{{ note.reason }}</span>
      <img class="icon" src="/assets/icons/elipsis-menu.svg" />
    </div>
    <div class="second-line">
      <span class="span2">{{ note.notes }}</span>
      <span class="span2">{{ note.datetime }}</span>
    </div>
  </div>
</div>
