import { Component, Inject, OnInit } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AsyncPipe, NgFor, NgStyle } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { map, Observable, startWith } from "rxjs";

@Component({
  selector: "app-filter-financial-dialog",
  templateUrl: "./filter-financial-dialog.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgFor,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    NgStyle,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    AsyncPipe,
    MatSelectModule,
    MatDialogModule,
    LocalizationModule,
  ],
  styleUrls: ["./filter-financial-dialog.component.scss"],
})
export class FilterFinancialDialogComponent implements OnInit {
  filterForm!: FormGroup;

  countriesList: any;
  filteredCountries$!: Observable<
    { id: number; name: string; code: string; codeAlpha3: string }[]
  >;
  countryControl = new FormControl("");
  isCountryDropdownOpened: boolean = false;
  selectedCountries: any;

  leaguesList: any;
  filteredLeagues$!: Observable<
    { id: number; name: string; code: string; codeAlpha3: string }[]
  >;
  leagueControl = new FormControl("");
  isLeagueDropdownOpened: boolean = false;
  selectedLeaguees: any;

  labelMap: any;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FilterFinancialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.labelMap = this.data.labelMap;
  }

  ngOnInit(): void {
    this.countriesList = this.data.countries;
    this.leaguesList = this.data.leagues;
    const filteredCountries = this.data?.filters.countryIds || [];
    const filteredLeagues = this.data?.filters.leagueIds || [];
    const filteredCurrency = this.data?.filters.currency || "";
    const filteredName = this.data?.filters.name || "";
    this.filterForm = this.fb.group({
      countries: [filteredCountries],
      leagues: [filteredLeagues],
      currency: [filteredCurrency],
      name: [filteredName],
    });

    this.filteredCountries$ = this.countryControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterCountries(value || ""))
    );

    this.filteredLeagues$ = this.leagueControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterLeagues(value || ""))
    );
  }

  private _filterCountries(value: string) {
    const filterValue = value.toLowerCase();
    return this.countriesList?.filter((country: any) =>
      country.name.toLowerCase().includes(filterValue)
    );
  }

  private _filterLeagues(value: string) {
    const filterValue = value.toLowerCase();
    return this.leaguesList?.filter((league: any) =>
      league.name.toLowerCase().includes(filterValue)
    );
  }

  applyFilters(): void {
    if (
      this.filterForm.value &&
      this.filterForm.value.countries &&
      this.filterForm.value.countries.length > 0
    ) {
      const uniqueArray = Array.from(new Set(this.filterForm.value.countries));
      this.filterForm.value.countries = uniqueArray;
    }

    if (
      this.filterForm.value &&
      this.filterForm.value.leagues &&
      this.filterForm.value.leagues.length > 0
    ) {
      const uniqueArray = Array.from(new Set(this.filterForm.value.leagues));
      this.filterForm.value.leagues = uniqueArray;
    }

    this.dialogRef.close(this.filterForm.value);
  }

  resetFilters() {
    this.filterForm.reset({
      countries: [],
      leagues: [],
      currency: "",
      name: "",
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  dropdownOpened(opened: boolean, inputId: string): void {
    this.selectedCountries = this.filterForm.get("countries")?.value || [];
    this.selectedLeaguees = this.filterForm.get("leagues")?.value || [];

    switch (inputId) {
      case "invisible-input-country":
        this.isCountryDropdownOpened = opened;
        break;
      case "invisible-input-league":
        this.isLeagueDropdownOpened = opened;
        break;
    }
    if (opened) {
      const inputElement = document.getElementById(inputId);
      if (inputElement) {
        inputElement.focus();
      }
    } else {
      switch (inputId) {
        case "invisible-input-country":
          this.countryControl.setValue("");
          break;
        case "invisible-input-league":
          this.countryControl.setValue("");
          break;
      }
    }
  }

  getInputStyles(control: FormControl) {
    const value = control.value;
    const hasLetters = value && value.trim().length > 0;
    return {
      opacity: hasLetters ? 1 : 0,
      zIndex: hasLetters ? 10 : -10,
    };
  }

  addCountry(newCountry: number) {
    if (!this.selectedCountries.includes(newCountry)) {
      this.selectedCountries.push(newCountry);
      this.filterForm.patchValue({
        countries: [...this.selectedCountries, newCountry],
      });
    }
  }

  addLeague(newLeague: number) {
    if (!this.selectedLeaguees.includes(newLeague)) {
      this.selectedLeaguees.push(newLeague);
      this.filterForm.patchValue({
        leagues: [...this.selectedLeaguees, newLeague],
      });
    }
  }

  onCountrySelectionChange(event: any): void {
    const selectedValue = event.value;
    if (selectedValue) {
      for (const c of selectedValue) {
        const country = this.countriesList?.find((x: any) => x.id === c);
        if (country) {
          this.addCountry(country.id);
        } else {
        }
      }
    } else {
    }
  }

  onLeagueSelectionChange(event: any): void {
    const selectedValue = event.value;
    if (selectedValue) {
      for (const c of selectedValue) {
        const league = this.leaguesList?.find((x: any) => x.id === c);
        if (league) {
          this.addLeague(league.id);
        } else {
        }
      }
    } else {
    }
  }
}
