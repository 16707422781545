<form [formGroup]="signinForm" #loginForm="ngForm" (ngSubmit)="onFormSubmit()">
  <div class="signin-form-container">
    <div class="fields-container">
      <!-- Email Field -->
      <div>
        <div class="field-item">
          <label for="email" class="custom-label">{{
            "EMAIL" | transloco
          }}</label>
          <input
            id="email"
            type="email"
            class="custom-input"
            [ngClass]="{
              'error-input': loginForm.submitted && usernameControl.invalid
            }"
            required
            [placeholder]="'EMAIL' | transloco"
            [formControl]="usernameControl"
          />
        </div>
        <div
          [ngClass]="{
            visible: loginForm.submitted && usernameControl.invalid
          }"
          class="error-message"
        >
          {{ "EMAIL_NOT_CORRECT" | transloco }}
        </div>
      </div>

      <!-- Password Field -->
      <div>
        <div class="field-item form-top-margin">
          <label for="password" class="custom-label">{{
            "PASSWORD" | transloco
          }}</label>
          <div class="password-container">
            <input
              id="password"
              [type]="hidePassword ? 'password' : 'text'"
              class="custom-input"
              required
              [placeholder]="'PASSWORD' | transloco"
              [formControl]="passwordControl"
              [ngClass]="{
                'error-input':
                  (loginForm.submitted && passwordControl.invalid) ||
                  displayIncorrectEmailOrPassword
              }"
            />
            <button
              type="button"
              class="password-toggle-button"
              (click)="hidePassword = !hidePassword"
            >
              <mat-icon matSuffix>{{
                hidePassword ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
            <div class="forgot-password">
              <a
                class="forgot-password-link"
                (click)="displayForgotPasswordForm()"
                >{{ "FORGOT_PASSWORD" | transloco }}</a
              >
            </div>
          </div>
        </div>
        <div
          [ngClass]="{
            visible: loginForm.submitted && passwordControl.invalid
          }"
          class="error-message"
        >
          {{ "ENTER_A_VALID_PASSWORD" | transloco }}
        </div>
        <div
          [ngClass]="{
            visible: displayIncorrectEmailOrPassword
          }"
          class="error-message"
        >
          {{ "INCORRECT_EMAIL_ADDRESS_OR_PASSWORD" | transloco }}
        </div>
      </div>
    </div>

    <div class="bottom-container">
      <div class="button-c">
        <button
          class="btn-7 signin-button"
          type="submit"
          [disabled]="isLoading"
        >
          {{ "SIGN_IN" | transloco }}
        </button>
      </div>

      <div class="or">{{ "OR" | transloco }}</div>
      <div class="login-options">
        <img
          id="customGoogleSignInButton"
          src="/assets/icons/google-sign-in.svg"
          alt="Google Sign-In"
          class="button-image"
        />
        <img
          (click)="loginWithFacebook()"
          src="/assets/icons/facebook-sign-in.svg"
          alt="Sign in with Facebook"
          class="button-image"
        />
        <img
          (click)="signInWithApple()"
          src="/assets/icons/apple-sign-in.svg"
          alt="Sign in with Apple"
          class="button-image"
        />
      </div>
      <div class="signup-container">
        <div class="field-row text-font">
          <div>{{ "DO_NOT_HAVE_AN_ACCOUNT" | transloco }}</div>
          <a class="signup-link" (click)="displaySignUpForm()">{{
            "SIGN_UP" | transloco
          }}</a>
        </div>
      </div>
    </div>
  </div>
</form>
