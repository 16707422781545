<span #label (click)="toggleMenu()" class="label">{{ 'LANGUAGE_SELF_LABEL' | transloco }}</span>
<i #icon2 class="arrow" (click)="toggleMenu()" [innerHTML]="IconArrowDown | safe: SafeTypes.html"></i>

<div
  #list
  class="locales"
  [class.show]="isShowMenu"
  [class.is-admin]="isAdmin$ | async"
>
  <div
    class="locale"
    *ngFor="let locale of locales"
    (click)="changeLocale(locale.locale)"
    [class.is-raw-locale]="SupportedLocales.raw === locale.locale"
  >
    {{ locale.label }}
  </div>
</div>

<studio-user-settings [hidePanelsOnOpenSearch]="hidePanelsOnOpenSearch" [isDiffUrl]="isDiffUrl"></studio-user-settings>
