import { animate, style, transition, trigger, query } from '@angular/animations';

export const DialogAnimation = [
  trigger('parentPopupWindow', [
    transition('false => true', [
      query('app-dialog-wrapper > *', [style({ transform: 'scale(0)' }), animate('0.3s ease-in', style({ transform: 'scale(1)' }))], {
        optional: true,
      }),
    ]),
  ]),
];
