<div
  [@inOutAnimation]
  class="notification"
  [attr.type]="notification.value.type"
  *ngFor="let notification of notificationList | keyvalue; trackBy: trackIdentify"
>
  <div class="title">{{ notification.value.title }}</div>

  <div class="body" *ngIf="isArray(notification.value.body); else singleElement">
    <div *ngFor="let item of notification.value.body">
      <span>{{ item }}</span>
      <br />
    </div>
  </div>

  <ng-template #singleElement>
    <div class="body">{{ notification.value.body }}</div>
  </ng-template>
</div>
