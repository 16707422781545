import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Store } from "@ngxs/store";
import { skip } from "rxjs/operators";
import { LocalizationModule } from "../../localization";
import { CommonModule } from "@angular/common";
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  emailPattern,
  loginAsEmailPattern,
  passwordPattern, signinPasswordPattern,
} from "../../../utils/control-utils";
import { AuthTokenInfo } from "../../../shared/models/auth-token.model";
import { LoginByToken } from "../../../store/user/user.actions";
import { NotificationTypes } from "../../../shared/constants/notification-types.enum";
import { AuthContract } from "../../../shared/contracts/auth.contract";
import { TranslocoService } from "@ngneat/transloco";
import { ToastService } from "../../../shared/services/toast.service";
import { MatIconModule } from "@angular/material/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";

declare var FB: any;
declare var AppleID: any;

@Component({
  standalone: true,
  selector: "events-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
  imports: [
    LocalizationModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  providers: [ToastService],
})
export class SignInComponent implements OnInit, OnDestroy {
  @Output() signInSuccessed = new EventEmitter();
  @Output() displaySignUp = new EventEmitter();
  @Output() displayForgotPassword = new EventEmitter();

  public usernameControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.pattern(loginAsEmailPattern),
  ]);
  public passwordControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.pattern(signinPasswordPattern),
  ]);

  public signinForm = new FormGroup({
    username: this.usernameControl,
    password: this.passwordControl,
  });

  public isLoading = false;

  hidePassword = true;
  displayIncorrectEmailOrPassword = false;

  app: any;
  auth: any;

  constructor(
    private authContract: AuthContract,
    private toast: ToastService,
    private store: Store,
    private transloco: TranslocoService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.signinForm.valueChanges.pipe(skip(2)).subscribe((data) => {
      if (this.displayIncorrectEmailOrPassword) {
        this.displayIncorrectEmailOrPassword = false;
        this.usernameControl.setErrors(null);
        this.passwordControl.setErrors(null);
      }
    });

    this.initializeGoogleSignIn();
  }

  loginWithFacebook(): void {
    const context = this;
    FB.init({
      appId: "1268064721081638",
      cookie: true,
      xfbml: true,
      version: "v21.0",
    });

    FB.login(
      (response: any) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          context.login("facebook", accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email" }
    );
  }

  initializeGoogleSignIn() {
    const context = this;

    google.accounts.id.initialize({
      client_id:
        "917992935210-f36hij2pruuct030i5bmmrejmnmp1oca.apps.googleusercontent.com",
      callback: (response: any) => {
        const token = response.credential;
        try {
          context.login("google", token);
        } catch (e) {
          console.log("ERROR WHEN LOG IN", e);
        }
      },
    });

    const customButton = document.getElementById("customGoogleSignInButton");
    if (customButton) {
      customButton.addEventListener("click", () => {
        google.accounts.id.prompt();
      });
    }
  }

  private clientId = "com.uventex.auth";
  private redirectUri = environment.CURRENT_URL + "auth/callback/apple";
  private scope = "email name";
  private state = "random-string";

  async signInWithApple() {
    AppleID.auth.init({
      clientId: this.clientId,
      redirectURI: this.redirectUri,
      response_type: "code id_token",
      state: this.state,
      scope: this.scope,
      usePopup: true,
    });
    const data = await AppleID.auth.signIn();
    if (data && data.authorization && data.authorization.id_token) {
      this.login("apple", data.authorization.id_token);
    }
  }

  ngOnDestroy(): void {}

  public async onFormSubmit(): Promise<void> {
    if (this.signinForm.invalid) {
      return;
    }

    this.login("password");
  }

  public login(
    grantType: "password" | "google" | "facebook" | "apple",
    token?: string
  ): void {
    this.isLoading = true;

    let email = this.usernameControl.value;
    let password = this.passwordControl.value;

    if (grantType !== "password") {
      if (!email) {
        email = "username";
      }
      if (!password) {
        password = "password";
      }
    }

    this.authContract.login(email, password, grantType, token).subscribe({
      next: (value: AuthTokenInfo) => {
        if (value != null && value.accessToken != null) {
          this.store
            .dispatch(new LoginByToken(value.accessToken))
            .subscribe((result) => {
              if (result?.user?.authUser?.eventAccount?.accessLevel) {
                const accessLevel =
                  result?.user?.authUser?.eventAccount?.accessLevel;
                if (accessLevel === "PROMOTER") {
                  this.router.navigateByUrl("dashboard");
                }
              }
            });
        }

        this.isLoading = false;
        this.signInSuccessed.emit();
      },
      error: (error: any) => {
        this.isLoading = false;

        if (error && error.error && error.error.code) {
          const code = error.error.code;
          if (code === 401) {
            this.displayIncorrectEmailOrPassword = true;
          }
        } else {
          const body = this.transloco.translate("ALERT_LOGIN_FAILED");
          this.toast.show({ body, type: NotificationTypes.error });
        }
      },
    });
  }

  displaySignUpForm() {
    this.displaySignUp.emit();
  }

  displayForgotPasswordForm() {
    this.displayForgotPassword.emit();
  }

  /*resetPassword() {
    const email = (this.resetByEmailController.value as string).trim();
    this.authController.resetPassword(email).subscribe(
      (data) => {
        this.hideForgotPassword();
        if (data.reset) {
          this.toastr.success(data.message);
          this.close.emit();
        }
        if (!data.reset) {
          this.toastr.error(data.message);
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      },
    );
  }

  showForgotPassword() {
    this.forgotPasswordVisible = true;
  }

  hideForgotPassword() {
    this.resetByEmailController.reset();
    this.forgotPasswordVisible = false;
  }*/
}
