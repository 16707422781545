import { Component, Input, OnInit } from "@angular/core";
import { CommonModule, DatePipe, NgFor, NgIf } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import { debounceTime, firstValueFrom, Subject } from "rxjs";
import { RouterModule } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { ApiService } from "src/app/shared/constants/api.service";
import { Event } from "src/app/shared/models/event.model";
import { EventsSelectorService } from "src/app/shared/services/events-selector.service";
import { CKEditorStandaloneWrapperModule } from "src/app/shared/wraper-module/ckeditor.modulte";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

@Component({
  selector: "app-waiver-and-info",
  templateUrl: "./waiver-and-info.component.html",
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    LocalizationModule,
    CommonModule,
    CKEditorStandaloneWrapperModule,
    RouterModule,
  ],
  styleUrls: ["./waiver-and-info.component.scss"],
  providers: [DatePipe],
})
export class WaiverAndInfoComponent implements OnInit {
  @Input() event: Event | undefined;
  currentLang: string = "en";

  currentName: string = "";

  waiver: string = "";
  info: string = "";

  public editor1: any = DecoupledEditor;
  public editor2: any = DecoupledEditor;

  public ckeditorConfig1 = {
    placeholder: "Start typing...",
    toolbar: {
      items: [
        "heading",
        "|",
        "fontsize",
        "alignment",
        "|",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "|",
        "link",
        "|",
        "outdent",
        "indent",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "blockQuote",
        "|",
        "undo",
        "redo",
      ],
    },
  };
  public ckeditorConfig2 = {
    placeholder: "Start typing...",
    toolbar: {
      items: [
        "heading",
        "|",
        "fontsize",
        "alignment",
        "|",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "|",
        "link",
        "|",
        "outdent",
        "indent",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "blockQuote",
        "|",
        "undo",
        "redo",
      ],
    },
  };

  statusMessageInfo1: string = "";
  statusMessageInfo2: string = "";
  statusMessageWaiver1: string = "";
  statusMessageWaiver2: string = "";
  statusClassInfo: "" | "saving" | "saved" | "error" = "";
  statusClassWaiver: "" | "saving" | "saved" | "error" = "";

  private saveSubjectInfo = new Subject<string>();
  private saveSubjectWaiver = new Subject<string>();

  constructor(
    private apiService: ApiService,
    private eventsSelectorService: EventsSelectorService,
    private transloco: TranslocoService,
  ) {
    this.transloco.langChanges$.subscribe((lang) => {
      const currentLang = lang.split("-")[0];
      this.currentLang = currentLang;
    });

    this.saveSubjectInfo.pipe(debounceTime(1000)).subscribe((content) => {
      this.submitInfo();
    });

    this.saveSubjectWaiver.pipe(debounceTime(1000)).subscribe((content) => {
      this.submitWaiver();
    });
  }

  ngOnInit(): void {
    this.eventsSelectorService.selectedEvent$.subscribe(async (ev) => {
      this.event = { ...ev };
      if (this.event && this.event.id) {
        const data: any = await firstValueFrom(
          this.apiService.getWaiverAndInfo(this.event.id)
        );
        this.updateData(data);
      }
    });
  }

  updateData(data: any) {
    if (!data) {
      return;
    }
    if (data.waiver) {
      this.waiver = data.waiver;
    }
    if (data.info) {
      this.info = data.info;
    }

    this.currentName = data.name ?? "";
  }

  onEditorWaiverChange() {
    this.statusMessageWaiver1 = "SAVING_CHANGES";
    this.statusMessageWaiver2 = "";
    this.statusClassWaiver = "saving";
    this.saveSubjectWaiver.next(this.waiver);
  }

  onEditorInfoChange() {
    this.statusMessageInfo1 = "SAVING_CHANGES";
    this.statusMessageInfo2 = "";
    this.statusClassInfo = "saving";
    this.saveSubjectInfo.next(this.info);
  }

  async submitWaiver() {
    if (!this.event) {
      return;
    }
    try {
      const data = await firstValueFrom(
        this.apiService.putWaiverAndInfo(
          this.event.id,
          this.currentName,
          this.info,
          this.waiver
        )
      );

      this.statusMessageWaiver1 = "SAVED_CHANGES";
      this.statusMessageWaiver2 = "";
      this.statusClassWaiver = "saved";
    } catch (e) {
      console.error(e);
      this.statusMessageWaiver1 = "SAVED_NOT1";
      this.statusMessageWaiver2 = "SAVED_NOT2";
      this.statusClassWaiver = "error";
    }
  }

  public onReadyEditor(editor: any) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  async submitInfo() {
    if (!this.event) {
      return;
    }
    try {
      const data = await firstValueFrom(
        this.apiService.putWaiverAndInfo(
          this.event.id,
          this.currentName,
          this.info,
          this.waiver
        )
      );

      this.statusMessageInfo1 = "SAVED_CHANGES";
      this.statusMessageInfo2 = "";
      this.statusClassInfo = "saved";
    } catch (e) {
      console.error(e);
      this.statusMessageInfo1 = "SAVED_NOT1";
      this.statusMessageInfo2 = "SAVED_NOT2";
      this.statusClassInfo = "error";
    }
  }
}
