<div class="cont">
  <div class="header">
    <h2 mat-dialog-title class="title">
      {{ labelMap.CALL_MEDIC_DIALOG_CALL_MEDIC }}
    </h2>
    <button
      class="close-button"
      mat-icon-button
      (click)="closeDialog()"
      aria-label="Close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content class="c-body">
      <span class="c-label">{{ labelMap.CALL_MEDIC_DIALOG_NOTIFY }}</span>
      <mat-form-field appearance="fill">
        <mat-select formControlName="medic">
          <mat-option
            *ngFor="let option of medicsListOptions"
            [value]="option.personId"
          >
            {{ option.name + (option.email ? " <" + option.email + ">" : "") }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <span class="c-label">{{ labelMap.CALL_MEDIC_DIALOG_AREAS }}</span>
      <mat-form-field appearance="fill">
        <mat-select
          formControlName="area"
          (selectionChange)="onRingChanged()"
          [(value)]="selectedRingId"
        >
          <mat-option *ngFor="let area of eventRings" [value]="area.id">
            {{ area.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <span class="c-label">{{ labelMap.CALL_MEDIC_DIALOG_MESSAGE }}</span>
      <textarea
        matInput
        formControlName="message"
        placeholder="{{ labelMap['START_TYPING...'] }}"
        class="t-area"
      ></textarea>
    </div>
    <div mat-dialog-actions>
      <div class="bottom">
        <button class="action-b">
          {{ labelMap.CALL_MEDIC_DIALOG_SEND_TO_MEDIC }}
        </button>
      </div>
    </div>
  </form>
</div>
