import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {User} from "../../../shared/models/user.model";
import {TranslocoService} from "@ngneat/transloco";
import {MatAutocompleteModule, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {NgForOf} from "@angular/common";
import {LocalizationModule} from "../../localization";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {AuthContract} from "../../../shared/contracts/auth.contract";
import {AuthTokenInfo} from "../../../shared/models/auth-token.model";
import {LoginByToken} from "../../../store/user/user.actions";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-login-as-dialog',
  templateUrl: './login-as-dialog.component.html',
  styleUrls: ["./login-as-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    NgForOf,
    ReactiveFormsModule,
    LocalizationModule,
    MatIconModule,
    MatSelectModule,
  ],
  providers: [TranslocoService],
  standalone: true
})
export class LoginAsDialogComponent {

  public dialogResult = new EventEmitter<void | number>();

  public userOptions: User[] = [];

  form: FormGroup;

  @ViewChild(MatAutocompleteTrigger) autoComplete!: MatAutocompleteTrigger;

  @ViewChild('userSearch') userSearch!: ElementRef;

  constructor(
    private authContract: AuthContract,
    private fb: FormBuilder,
    private transloco: TranslocoService,
    private dialogRef: MatDialogRef<LoginAsDialogComponent>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
  ) {
    this.form = this.fb.group({
      user: [null, [Validators.required]],
    });

    // this.form.get('user').
    setTimeout(() => {
      this.userSearch!.nativeElement.focus();
    }, 500);
  }

  public login(): void {
    // this.dialogResult.emit((this.studioControl.value as UserStudio).userId);

    let loginName = this.form.value.user.email;
    this.authContract.loginAs(loginName).subscribe({
        next: (value: AuthTokenInfo) => {
          if (value != null && value.accessToken != null) {
            this.store.dispatch(new LoginByToken(value.accessToken)).subscribe((result) => {
              /*
              if (result?.user?.authUser?.eventAccount?.accessLevel) {
                const accessLevel = result?.user?.authUser?.eventAccount?.accessLevel;
                if (accessLevel === 'PROMOTER') {
                  this.router.navigateByUrl('dashboard');
                }
              }
              */
              this.dialogRef.close();
            });
          }
        },
        error: (error: any) => {
          const body = this.transloco.translate('ALERT_LOGIN_FAILED');
          // this.toast.show({ body, type: NotificationTypes.error });
        }
      }
    );  }

  closeDialog() {
    this.dialogRef.close();
  }

  getUsersList(userName: any) {
    this.userOptions = [];
    if (userName.target.value.length >= 3) {
      const userSearchString = encodeURIComponent(userName.target.value);
      this.authContract.loginAsSearch(userSearchString).subscribe((s: User[]) => {
          this.userOptions = [...s];
          this.autoComplete.openPanel();
        }
      );
    }
  }

  setUser(user: any) {
    const userName = user.target.value;
    const selectedUser = this.userOptions.find((v) => v.email.toLowerCase().includes(userName))

    if (selectedUser) {
      this.form.patchValue({
        user: selectedUser
      })
    }
  }

  displayUserName(user: User): string {
    return user && user.email ? user.email : '';
  }
  
}
