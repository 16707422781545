<div class="header">
  <h2 mat-dialog-title class="title2">{{ labelMap.FILTER }}</h2>
  <div class="end">
    <span class="reset" (click)="resetFilters()">
      {{ labelMap.RESET_FILTER }}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <path
          d="M4.99083 8.12919e-06C3.65476 0.00240732 2.44167 0.528899 1.54607 1.38479L0.826028 0.664746C0.52119 0.359907 0 0.575794 0 1.00688V3.70968C0 3.97692 0.216633 4.19355 0.483871 4.19355H3.18667C3.61776 4.19355 3.83365 3.67236 3.52883 3.36753L2.6871 2.52579C3.30935 1.94315 4.11454 1.62041 4.96996 1.61303C6.83282 1.59694 8.40308 3.1045 8.38698 5.0292C8.37169 6.85502 6.89145 8.3871 5 8.3871C4.17083 8.3871 3.38716 8.09117 2.76956 7.54928C2.67393 7.46539 2.52952 7.47049 2.43956 7.56043L1.63992 8.36006C1.54169 8.45829 1.54655 8.61843 1.64964 8.71153C2.53625 9.51236 3.71117 10 5 10C7.76141 10 9.99998 7.76143 10 5.00004C10 2.2418 7.74907 -0.00493138 4.99083 8.12919e-06Z"
          fill="#525252"
        />
      </svg>
    </span>
    <svg
      class="close"
      (click)="closeDialog()"
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <line
        y1="-1"
        x2="12"
        y2="-1"
        transform="matrix(-0.707107 -0.707106 0.707108 -0.707106 10.4844 8.48535)"
        stroke="#525252"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="12"
        y2="-1"
        transform="matrix(0.707107 -0.707106 0.707107 0.707106 2 9.89941)"
        stroke="#525252"
        stroke-width="2"
      />
    </svg>
  </div>
</div>

<div class="dialog-container">
  <mat-dialog-content>
    <form [formGroup]="filterForm" class="filter-form">
      <div style="position: relative" class="form-field">
        <!-- Invisible input for filtering -->
        <input
          id="invisible-input-country"
          [formControl]="countryControl"
          type="text"
          [ngStyle]="getInputStyles(countryControl)"
          class="invisible-input"
        />
        <!-- Actual select dropdown -->
        <mat-form-field appearance="fill" class="custom-select-field">
          <mat-select
            placeholder="{{ labelMap.COUNTRY }}"
            formControlName="countries"
            multiple
            (selectionChange)="onCountrySelectionChange($event)"
            (openedChange)="dropdownOpened($event, 'invisible-input-country')"
          >
            <mat-option
              *ngFor="let country of filteredCountries$ | async"
              [value]="country.id"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div style="position: relative" class="form-field">
        <!-- Invisible input for filtering -->
        <input
          id="invisible-input-league"
          [formControl]="leagueControl"
          type="text"
          [ngStyle]="getInputStyles(leagueControl)"
          class="invisible-input"
        />
        <!-- Actual select dropdown -->
        <mat-form-field appearance="fill" class="custom-select-field">
          <mat-select
            placeholder="{{ labelMap.LEAGUE }}"
            formControlName="leagues"
            multiple
            (selectionChange)="onLeagueSelectionChange($event)"
            (openedChange)="dropdownOpened($event, 'invisible-input-league')"
          >
            <mat-option
              *ngFor="let league of filteredLeagues$ | async"
              [value]="league.id"
            >
              {{ league.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Countries</mat-label>
          <mat-select formControlName="countries" multiple>
            <mat-option
              *ngFor="let country of countriesList"
              [value]="country.id"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>{{ labelMap.CURRENCY }}</mat-label>
          <input
            matInput
            formControlName="currency"
            placeholder="{{ labelMap.CURRENCY }}"
          />
        </mat-form-field>
      </div>

      <div class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>{{ labelMap.SEARCH_BY_NAME }}</mat-label>
          <input
            matInput
            formControlName="name"
            placeholder="{{ labelMap.ENTER_NAME }}"
          />
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-button class="cancel-button" (click)="closeDialog()">
      {{ labelMap.CANCEL }}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="apply-button"
      (click)="applyFilters()"
    >
      {{ labelMap.APPLY }}
    </button>
  </mat-dialog-actions>
</div>
