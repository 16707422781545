import { Component, Inject, OnInit } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AsyncPipe, NgFor, NgIf, NgStyle } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { map, Observable, startWith } from "rxjs";

@Component({
  selector: "app-adjustment-finance-dialog",
  templateUrl: "./adjustment-finance-dialog.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    LocalizationModule,
  ],
  styleUrls: ["./adjustment-finance-dialog.component.scss"],
})
export class AdjustmentFinanceDialogComponent implements OnInit {
  event: any;
  labelMap: any;

  adjustment: number = 0;
  reason: string = "";
  error: string = "";

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AdjustmentFinanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.labelMap = this.data.labelMap;
  }

  ngOnInit(): void {
    this.event = this.data.event;

    this.adjustment = this.event.convenienceAdjustment || 0;
    this.reason = this.event.convenienceAdjustmentNote || "";
  }

  applyFilters(): void {
    this.error = "";

    // if (!this.adjustment) {
    //   this.error = this.labelMap["ENTER_A_VALID_NUMBER"];
    //   return;
    // }
    const result = {
      adjustment: this.adjustment,
      reason: this.reason,
    };
    this.dialogRef.close(result); // P
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
